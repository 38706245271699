import Vue from 'vue';
import Vuex from 'vuex';

import settings from './settings'
import statics from './settings/statics'
import systemMessages from './settings/systemMessages'
import config from './settings/config'

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        settings,
        statics,
        systemMessages,
        config
    }
});