const state = {
    sessionToken: null,
    userName: 'Unknown user',
    userAvatar: null,
    userId: null,
    apps: [],
    tipoSesion: [],
    role: "user",
    sesionCursoProfesor: false,
    sesionCursoAlumno: false,
    sesionCursoPadre: false,
    vistaSeguimiento: false,
    timezone: 'America/Mexico_City',
    menu: [],
    tokenSesionHijo: null,
}

const getters = {
    sessionToken: state => {
        return state.sessionToken;
    },
    userName: state => {
        return state.userName;
    },
    userAvatar: state => {
        return state.userAvatar;
    },
    userId: state => {
        return state.userId;
    },
    apps: state => {
        return state.apps;
    },
    tipoSesion: state => {
        return state.tipoSesion;
    },
    role: state => {
        return state.role;
    },
    sesionCursoProfesor: state => {
        return state.sesionCursoProfesor;
    },
    sesionCursoAlumno: state => {
        return state.sesionCursoAlumno;
    },
    sesionCursoPadre: state => {
        return state.sesionCursoPadre;
    },
    vistaSeguimiento: state => {
        return state.vistaSeguimiento;
    },
    timezone: state => {
        return state.timezone;
    },
    menu: state => {
        return state.menu;
    },
    tokenSesionHijo: state => {
        return state.tokenSesionHijo;
    }
}

const actions = {
    setSessionToken(context, payload) {
        context.commit('setSessionTokenHandler', payload);
    },
    setUserName(context, payload) {
        context.commit('setUserNameHandler', payload);
    },
    setUserAvatar(context, payload) {
        context.commit('setUserAvatarHandler', payload);
    },
    setUserId(context, payload) {
        context.commit('setUserIdHandler', payload);
    },
    setApps(context, payload) {
        context.commit('setAppsHandler', payload);
    },
    setTipoSesion(context, payload) {
        context.commit('setTipoSesionHandler', payload);
    },
    setSesionCursoProfesor(context, payload) {
        context.commit('setSesionCursoProfesorHandler', payload);
    },
    setSesionCursoAlumno(context, payload) {
        context.commit('setSesionCursoAlumnoHandler', payload);
    },
    setSesionCursoPadre(context, payload) {
        context.commit('setSesionCursoPadreHandler', payload);
    },
    setTimezone(context, payload) {
        context.commit('setTimezoneHandler', payload);
    },
    setMenu(context, payload) {
        context.commit("setMenuHandler", payload);
    },
    setTokenSesionHijo(context, payload) {
        context.commit('setTokenSesionHijoHandler', payload);
    },
    setVistaSeguimiento(context, payload) {
        context.commit("setVistaSeguimientoHandler", payload);
    },
}

// mutations
const mutations = {
    setSessionTokenHandler(state, token) {
        state.sessionToken = token;
    },
    setUserNameHandler(state, name) {
        state.userName = name;
    },
    setUserAvatarHandler(state, userAvatar) {
        state.userAvatar = userAvatar;
    },
    setUserIdHandler(state, userId) {
        state.userId = userId;
    },
    setAppsHandler(state, apps) {
        state.apps = apps;
    },
    setTipoSesionHandler(state, tipoSesion) {
        state.tipoSesion = tipoSesion;
    },
    setSesionCursoProfesorHandler(state, sesionCursoProfesor) {
        state.sesionCursoProfesor = sesionCursoProfesor;
    },
    setSesionCursoAlumnoHandler(state, sesionCursoAlumno) {
        state.sesionCursoAlumno = sesionCursoAlumno;
    },
    setSesionCursoPadreHandler(state, sesionCursoPadre) {
        state.sesionCursoPadre = sesionCursoPadre;
    },
    setTimezoneHandler(state, timezone) {
        state.timezone = timezone;
    },
    setMenuHandler(state, menu) {
        state.menu = menu;
    },
    setTokenSesionHijoHandler(state, token) {
        state.tokenSesionHijo = token;
    },
    setVistaSeguimientoHandler(state, vistaSeguimiento) {
        state.vistaSeguimiento = vistaSeguimiento;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}