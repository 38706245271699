
const state = {
    systemMessageTitle: '',
    systemMessage: '',
    systemMessageType: 'error',
    showSystemMessage: false
}

const getters = {
    systemMessageTitle: state => {
        return state.systemMessageTitle;
    },
    systemMessage: state => {
        return state.systemMessage;
    },
    systemMessageType: state => {
        return state.systemMessageType;
    },
    showSystemMessage: state => {
        return state.showSystemMessage;
    }
}

const actions = {
    setSystemMessageTitle(context, payload) {
        context.commit('setSystemMessageTitleHandler', payload);
    },
    setSystemMessage(context, payload) {
        context.commit('setSystemMessageHandler', payload);
    },
    setSystemMessageType(context, payload) {
        context.commit('setSystemMessageTypeHandler', payload);
    },
    setShowSystemMessage(context, payload) {
        context.commit('setShowSystemMessageHandler', payload);
    }
}

const mutations = {
    setSystemMessageTitleHandler(state, message) {
        state.systemMessageTitle = message;
    },
    setSystemMessageHandler(state, message) {
        state.systemMessage = message;
    },
    setSystemMessageTypeHandler(state, message) {
        state.systemMessageType = message;
    },
    setShowSystemMessageHandler(state, show) {
        state.showSystemMessage = show;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}