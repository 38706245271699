import { store } from '../../../store/store';

export function serverErrorMessage() {
    store.dispatch("setSystemMessageTitle", 'Ooops! Esto es incómodo.');
    store.dispatch("setSystemMessageType", 'serverError');
    store.dispatch("setSystemMessage", 'Ocurrió un error en el servidor 😓');
    store.dispatch("setShowSystemMessage", true);
}

export function appErrorMessage() {
    store.dispatch("setSystemMessageTitle", 'Ooops! Esto es incómodo.');
    store.dispatch("setSystemMessageType", 'appError');
    store.dispatch("setSystemMessage", 'Ocurrió un error en la aplicación web 😓');
    store.dispatch("setShowSystemMessage", true);
}

export function networkErrorMessage() {
    store.dispatch("setSystemMessageTitle", 'Ooops! No se pudo conectar al servidor.');
    store.dispatch("setSystemMessageType", 'networkError');
    store.dispatch("setSystemMessage", 'Inténtalo nuevamente');
    store.dispatch("setShowSystemMessage", true);
}

export function systemErrorMessage(message) {
    store.dispatch("setSystemMessageTitle", 'Ooops!');
    store.dispatch("setSystemMessageType", 'appError');
    store.dispatch("setSystemMessage", message);
    store.dispatch("setShowSystemMessage", true);
}