const state = {
    horarioPersonalizado: false,
    horasPersonalizadas: [],
}

const getters = {
    horarioPersonalizado: state => {
        return state.horarioPersonalizado;
    },
    horasPersonalizadas: state => {
        return state.horasPersonalizadas;
    },
}

const actions = {
    setHorarioPersonalizado(context, payload) {
        context.commit("setHorarioPersonalizadoHandler", payload);
    },
    setHorasPersonalizadas(context, payload) {
        context.commit("setHorasPersonalizadasHandler", payload);
    },
};

// mutations
const mutations = {
    setHorarioPersonalizadoHandler(state, horarioPersonalizado) {
        state.horarioPersonalizado = horarioPersonalizado;
    },
    setHorasPersonalizadasHandler(state, horasPersonalizadas) {
        state.horasPersonalizadas = horasPersonalizadas;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
}