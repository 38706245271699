import {
    serverErrorMessage,
    appErrorMessage,
    networkErrorMessage,
    systemErrorMessage
} from '../components/globals/services/systemMessages.service';

export default {

    install(Vue) {
        Vue.prototype.$serverErrorMessage = async () => {
            serverErrorMessage();
        }

        Vue.prototype.$appErrorMessage = async () => {
            appErrorMessage();
        }

        Vue.prototype.$networkErrorMessage = async () => {
            networkErrorMessage();
        }

        Vue.prototype.$systemErrorMessage = async (message) => {
            systemErrorMessage(message);
        }
    }
}
