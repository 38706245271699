import templateIcons from '../../components/globals/template/icons'

const state = {

    httpURL: process.env.VUE_APP_httpURL,
    redirect: process.env.VUE_APP_redirect,
    redirectEmpresa: process.env.VUE_APP_redirectEmpresa,
    redirectEscuela: process.env.VUE_APP_redirectEscuela,
    redirectMaster: process.env.VUE_APP_redirectMaster,
    httpH5pURL: process.env.VUE_APP_H5P_httpURL,

    discordURL: process.env.VUE_APP_discordURL,
    imageURL: process.env.VUE_APP_imageURL,
    videoURL: process.env.VUE_APP_videoURL,
    documentsURL: process.env.VUE_APP_documentsURL,
    appName: 'Campus',
    app: 'campus',
    systemVersion: '3.0',
    ...templateIcons
}

const getters = {
    httpURL: state => {
        return state.httpURL;
    },
    redirect: state => {
        return state.redirect;
    },
    redirectEmpresa: state => {
        return state.redirectEmpresa;
    },
    redirectEscuela: state => {
        return state.redirectEscuela;
    },
    redirectMaster: state => {
        return state.redirectMaster;
    },
    httpH5pURL: state => {
        return state.httpH5pURL;
    },
    discordURL: state => {
        return state.discordURL;
    },
    imageURL: state => {
        return state.imageURL;
    },
    videoURL: state => {
        return state.videoURL;
    },
    documentsURL: state => {
        return state.documentsURL;
    },
    appName: state => {
        return state.appName;
    },
    app: state => {
        return state.app;
    },
    systemVersion: state => {
        return state.systemVersion;
    },
    actividadArchivoIcon: state => {
        return state.actividadArchivoIcon;
    },
    actividadDirectaIcon: state => {
        return state.actividadDirectaIcon;
    },
    actividadForoIcon: state => {
        return state.actividadForoIcon;
    },
    actividadEncuestaIcon: state => {
        return state.actividadEncuestaIcon;
    },
    examenIcon: state => {
        return state.examenIcon;
    },
    listaCotejoIcon: state => {
        return state.listaCotejoIcon;
    },
    rubricaIcon: state => {
        return state.rubricaIcon;
    },
    agregarContenidoIcon: state => {
        return state.agregarContenidoIcon;
    }
}

export default {
    state,
    getters
}